@keyframes bounce {
	0%,
	75%,
	100% {
		-webkit-transform: translateY(0);
		-ms-transform: translateY(0);
		-o-transform: translateY(0);
		transform: translateY(0);
	}

	25% {
		-webkit-transform: translateY(-15px);
		-ms-transform: translateY(-15px);
		-o-transform: translateY(-15px);
		transform: translateY(-15px);
	}
}
