.overlay {
	background-color: rgba(0, 0, 0, 0.35);
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1050;
	overflow-y: auto;
	align-items: center;
	display: none;
}

.root {
	border-radius: 10px;
	background: #fff;
	z-index: 1100;
	max-width: 90%;
	width: auto;
	margin: 10vh auto 0;
	box-shadow: 0 0 2rem rgba(0, 0, 0, 0.25);
	position: relative;
}

.inner {
	/* padding: 3rem 2.4rem; */
}

.visible {
	display: flex;
}

.visible > .root {
	animation: modalShow 0.2s ease-out;
	animation-fill-mode: both;
	animation-play-state: running;
}

.close {
	position: absolute;
	display: block;
	cursor: pointer;
	top: 1rem;
	right: 1.2rem;
	font-size: 1.7rem;
	color: darkgrey;
}

.actions {
	display: flex;
}

.actions > * {
	flex-grow: 1;
}

@keyframes modalShow {
	from {
		transform: translateY(-4rem);
	}
	to {
		transform: translateY(0);
	}
}
