.md-radio {
	margin: 16px 0;
}
.md-radio.md-radio-inline {
	display: inline-block;
}
.md-radio input[type='radio'] {
	display: none;
}
.md-radio input[type='radio']:checked + label:before {
	border-color: #337ab7;
	animation: ripple 0.2s linear forwards;
}
.md-radio input[type='radio']:checked + label:after {
	transform: scale(1);
}
.md-radio label {
	display: inline-block;
	height: 20px;
	position: relative;
	padding: 0 30px;
	margin-bottom: 0;
	cursor: pointer;
	vertical-align: bottom;
}
.md-radio label:before,
.md-radio label:after {
	position: absolute;
	content: '';
	border-radius: 50%;
	transition: all 0.3s ease;
	transition-property: transform, border-color;
}
.md-radio label:before {
	left: 0;
	top: 0;
	width: 20px;
	height: 20px;
	border: 2px solid rgba(0, 0, 0, 0.54);
}
.md-radio label:after {
	top: 7px;
	left: 7px;
	width: 10px;
	height: 10px;
	transform: scale(0);
	background: #337ab7;
}
